import { useMutation, useQuery } from 'villus';
import type { AppliedMwGiftCards } from '~/graphql-types.gen';
import {
  ExtendedCustomerCartDocument,
  ExtendedGuestCartDocument,
  ApplyGiftCardToCartDocument,
  RemoveGiftCardFromCartDocument,
  ExtendApplyCouponToCartDocument,
  ExtendGuestPaymentMethodsDocument,
  ExtendPaymentMethodsDocument,
  ApplyDsquareLoyaltyToCartDocument,
  RemoveDsquareLoyaltyToCartDocument,
  type AppliedDsquareLoyality,
  type Cart,
  ExtendedStoreConfigDocument,
  ExtendSetPaymentMethodOnCartFeesDocument,
  GetCartFawryIframeUrlDocument,
} from '~/graphql/ExtendedCart';
import type { CartPromotionsFragment, GiftCardCartDataFragment } from '~/graphql/fragments';
import { resolveCartPromotions } from '~/utils/cart';
import type { CartPromotions, ErrorMapping, ExtendedCartItem } from '~/utils/types';

export type AppliedDsquareloyaltyMapped = {
  code: AppliedDsquareLoyality['code'];
  discount: AppliedDsquareLoyality['applied_discount'];
  remaining: AppliedDsquareLoyality['remaining_balance'];
  applied: AppliedDsquareLoyality['applied_discount'];
};

type DsquareLoyaltyFragment = {
  applied_dsquare_voucher?: AppliedDsquareLoyality | null;
};

export type ExtendedCartState = {
  appliedGiftCard: AppliedMwGiftCards | undefined | null;
  cartGifts: ExtendedCartItem[] | [];
  cartPromotions: CartPromotions;
  appliedDsquareVoucher: AppliedDsquareloyaltyMapped | undefined;
};

const extendedCartState = reactive<ExtendedCartState>({
  appliedGiftCard: undefined,
  cartGifts: [],
  cartPromotions: {
    options: undefined,
    eligiblePromotionIdx: 0,
  },
  appliedDsquareVoucher: undefined,
});

/**
 * Gift Cards
 */

export function useApplyGiftCard() {
  const { cartState, mapCartState, patchCartState } = useStoredCart();
  const { execute, isFetching } = useMutation(ApplyGiftCardToCartDocument);

  async function applyGiftCard(code: string) {
    const { data, error } = await execute({
      input: { cart_id: cartState.value.cartId, gift_card_code: code },
    });

    if (error && /\[GraphQL\] Requested Gift Card doesn't exist/gm.test(error.message)) {
      throw new Error('invalidGiftCard');
    }

    if (
      error &&
      new RegExp(`\\[GraphQL\\] Gift Card ${code} is not enabled. Current status: Used`, 'gm').test(error.message)
    ) {
      throw new Error('giftCardNoBalance');
    }

    if (error && /\[GraphQL\] This Gift Card is already in the Quote./gm.test(error.message)) {
      throw new Error('usedGiftCard');
    }

    if (
      error &&
      new RegExp(`\\[GraphQL\\] Gift Card ${code} is not enabled. Current status: Inactive`, 'gm').test(error.message)
    ) {
      throw new Error('giftCardNotActive');
    }
    patchCartState(mapCartState(data?.response?.cart));
    patchExtendedCartState(mapExtendedCartState(data?.response?.cart));

    const giftCardPaymentMethods = computed(() => data?.response?.cart?.available_payment_methods);

    return { data, error, giftCardPaymentMethods };
  }
  return {
    isFetching,
    applyGiftCard,
  };
}

export function useRemoveGiftCard() {
  const { execute, isFetching } = useMutation(RemoveGiftCardFromCartDocument);
  const { resolveException } = useExceptions('cart');
  const { cartState, mapCartState, patchCartState } = useStoredCart();

  async function removeGiftCard(code: string) {
    const { data, error } = await execute({
      input: { cart_id: cartState.value.cartId, gift_card_code: code },
    });

    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(resolveException(error).message);
    }

    patchCartState(mapCartState(data?.response?.cart));
    patchExtendedCartState(mapExtendedCartState(data?.response?.cart));

    const giftCardPaymentMethods = computed(() => data?.response?.cart?.available_payment_methods);
    return { data, error, giftCardPaymentMethods };
  }
  return {
    isFetching,
    removeGiftCard,
  };
}

export function useFawryIframeOnCart() {
  const { cartState } = useStoredCart();
  const fawryIframe = ref<string>('');
  const { execute, isFetching } = useQuery({
    query: GetCartFawryIframeUrlDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  async function getFawryIframe() {
    try {
      const { data, error } = await execute({
        variables: {
          cartId: cartState.value.cartId,
        },
      });

      if (error) {
        throw new Error(error.message);
      }
      if (!data?.fawryIframeUrl) {
        throw new Error('something went wrong');
      }
      if (data.fawryIframeUrl) {
        fawryIframe.value = data.fawryIframeUrl;
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  }

  return {
    getFawryIframe,
    fawryIframe,
    isFetching,
  };
}

export function useExtendPaymentFee() {
  const { cartState } = useStoredCart();
  const { execute } = useMutation(ExtendSetPaymentMethodOnCartFeesDocument);

  async function setPaymentMethodFees(code: string) {
    const { data } = await execute({
      input: {
        cart_id: cartState.value.cartId,
        payment_method: {
          code,
        },
      },
    });

    return data?.response;
  }
  return {
    setPaymentMethodFees,
  };
}

export function useExtendedCartAttributes() {
  const { cartState } = useStoredCart();
  return {
    ...toRefs(extendedCartState),
    hasInvalidItems: computed(() => cartState.value.items.some((item: ExtendedCartItem) => item.isInvalidItem)),
  };
}

function mapExtendedCartState(
  cart?: (GiftCardCartDataFragment & CartPromotionsFragment & DsquareLoyaltyFragment) | null,
): ExtendedCartState {
  if (!cart) {
    return extendedCartState;
  }

  return {
    appliedGiftCard:
      cart?.applied_mw_gift_cards === null
        ? null
        : cart?.applied_mw_gift_cards?.[0] || extendedCartState.appliedGiftCard || undefined,
    cartPromotions: resolveCartPromotions(cart.cart_promotions, extendedCartState),
    cartGifts: [],
    appliedDsquareVoucher: {
      code: cart?.applied_dsquare_voucher?.code,
      discount: cart?.applied_dsquare_voucher?.applied_discount,
      remaining: cart?.applied_dsquare_voucher?.remaining_balance,
      applied: cart?.applied_dsquare_voucher?.applied_discount,
    },
  };
}

function patchExtendedCartState(newState: Partial<typeof extendedCartState>) {
  Object.keys(newState).forEach(key => {
    (extendedCartState as any)[key] = (newState as any)[key];
  });
}

/**
 * Fetch the applied gift card if exists on first load
 * @param cookies
 */

export function useExtendedCartSetup() {
  const { getExtendedGuestCart, isFetchingExtendedGuestCart } = useExtendedGuestCart();
  const { getExtendedCustomerCart, isFetchingExtendedCustomerCart } = useExtendedCustomerCart();
  const cart = useCookie('cart');
  const { user } = useAuth();
  const isLoggedIn = computed(() => !!user.value?.email);

  usePaymentMethods();

  if (cart.value && !isLoggedIn.value) {
    getExtendedGuestCart();
  }

  if (isLoggedIn.value) {
    getExtendedCustomerCart();
  }

  return {
    isFetchingExtendedCart: computed(() => isFetchingExtendedCustomerCart || isFetchingExtendedGuestCart),
  };
}

/**
 * get guest extended cart
 * update cartState and extendedCartState accordingly
 */
function useExtendedGuestCart() {
  const { cartState, mapCartState, patchCartState } = useStoredCart();
  const { execute, isFetching: isFetchingExtendedGuestCart } = useQuery({
    query: ExtendedGuestCartDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  const { resolveException } = useExceptions('cart');

  async function getExtendedGuestCart() {
    const { data, error } = await execute({
      variables: {
        cartId: cartState.value.cartId,
      },
    });

    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(error.message);
    }

    if (data?.cart) {
      patchCartState(mapCartState(data?.cart));
      patchExtendedCartState(mapExtendedCartState(data?.cart));
    }
  }

  return {
    getExtendedGuestCart,
    isFetchingExtendedGuestCart,
  };
}

/**
 * get customer extended cart
 * update cartState and extendedCartState accordingly
 */
function useExtendedCustomerCart() {
  const { mapCartState, patchCartState } = useStoredCart();
  const { execute, isFetching: isFetchingExtendedCustomerCart } = useQuery({
    query: ExtendedCustomerCartDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  async function getExtendedCustomerCart() {
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    if (data?.cart) {
      patchCartState(mapCartState(data?.cart));
      patchExtendedCartState(mapExtendedCartState(data?.cart));
    }
  }

  return {
    getExtendedCustomerCart,
    isFetchingExtendedCustomerCart,
  };
}

export function useExtendApplyPromocode() {
  const { execute, isFetching } = useMutation(ExtendApplyCouponToCartDocument);
  const cartCookies = useCookie('cart');
  const { cartState, patchCartState, mapCartState } = useStoredCart();
  const { resolveException } = useExceptions('cart');

  if (cartCookies.value) {
    cartState.value.cartId = cartCookies.value;
  }

  async function applyCoupon(code: string) {
    const { data, error } = await execute({
      input: { cart_id: cartState.value.cartId, coupon_code: code },
    });

    if (error && /\[GraphQL\] Coupon is not active yet, please try again later\./.test((error as Error).message)) {
      throw new Error('notActive');
    }
    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(resolveException(error).message);
    }

    patchCartState(mapCartState(data?.response?.cart));
  }
  return {
    isFetching,
    applyCoupon,
  };
}

export function useExtendPaymentMethods() {
  const { cartState } = useStoredCart();

  const {
    execute: executeExtendGuestPaymentMethods,
    data: extendGuestPaymentMethods,
    isFetching: isFetchingExtendGuestPaymentMethod,
  } = useQuery({
    query: ExtendGuestPaymentMethodsDocument,
    variables: {
      cartId: cartState.value.cartId,
    },
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  const {
    execute: executeExtendCustomerPaymentMethods,
    data: extendCustomerPaymentMethods,
    isFetching: isFetchingExtendCustomerPaymentMethods,
  } = useQuery({
    query: ExtendPaymentMethodsDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  return {
    executeExtendCustomerPaymentMethods,
    executeExtendGuestPaymentMethods,
    extendedPaymentMethods: computed(
      () =>
        extendCustomerPaymentMethods.value?.cart?.paymentMethods ||
        extendGuestPaymentMethods.value?.cart?.paymentMethods ||
        [],
    ),
    isExtendPaymentMethodsLoading: computed(
      () => isFetchingExtendCustomerPaymentMethods.value || isFetchingExtendGuestPaymentMethod.value,
    ),
  };
}

export const useApplyDsquaresLoyaltyPoints = () => {
  const { cartState, patchCartState, mapCartState } = useStoredCart();

  const { execute, isFetching } = useMutation(ApplyDsquareLoyaltyToCartDocument);

  async function applyDsquaresLoyaltyPointsExecuter(voucherCode: string) {
    const { data, error } = await execute({
      input: {
        cart_id: cartState.value.cartId,
        voucher_code: voucherCode,
      },
    });
    const errorMapping: ErrorMapping = {
      '[GraphQL] Voucher code is invalid': 'invalidLoyaltyVoucher',
      '[GraphQL] Coupon number and customer MSISDN Mismatch': 'customerNotAllowed',
      '[GraphQL] Invalid merchant MSISDN': 'connectionError',
      '[GraphQL] This coupon number used before': 'couponHasBeenUsed',
      '[GraphQL] This coupon is expired': 'couponHasExpired',
      '[GraphQL] Wrong Coupon Number': 'invalidCoupon',
    };

    if (error) {
      const mappedError = Object.keys(errorMapping).find(key => new RegExp(key, 'gm'));
      if (mappedError) {
        throw new Error(errorMapping[mappedError]);
      }

      throw new Error('connectionError');
    }
    patchCartState(mapCartState(data?.applyDsquareLoyalityToCart?.cart));
    patchExtendedCartState(mapExtendedCartState(data?.applyDsquareLoyalityToCart?.cart as Cart));
    const voucherCard = computed(() => data?.applyDsquareLoyalityToCart?.cart);
    const PaymentMethods = computed(() => data?.applyDsquareLoyalityToCart?.cart?.available_payment_methods);
    return { data, error, voucherCard, PaymentMethods };
  }
  return { applyDsquaresLoyaltyPointsExecuter, isFetching };
};

export const useRemoveDsquaresLoyaltyPoints = () => {
  const { cartState, mapCartState, patchCartState } = useStoredCart();

  const { execute, isFetching } = useMutation(RemoveDsquareLoyaltyToCartDocument);

  async function removeDsquaresLoyaltyPointsExecuter(voucherCode: string) {
    const { data, error } = await execute({
      input: {
        cart_id: cartState.value.cartId,
        voucher_code: voucherCode,
      },
    });

    if (error) {
      throw new Error(error.message);
    }

    patchCartState(mapCartState(data?.removeDsquareLoyalityFromCart?.cart));
    patchExtendedCartState(mapExtendedCartState(data?.removeDsquareLoyalityFromCart?.cart as Cart));
    const voucherCard = computed(() => data?.removeDsquareLoyalityFromCart?.cart);
    const PaymentMethods = computed(() => data?.removeDsquareLoyalityFromCart?.cart?.available_payment_methods);

    return { data, error, voucherCard, PaymentMethods };
  }
  return { removeDsquaresLoyaltyPointsExecuter, isFetching };
};

export function useExtendedStoreConfig() {
  const { execute, isFetching: isFetchingExtendedStoreConfig } = useQuery({
    query: ExtendedStoreConfigDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  async function getExtendedStoreConfig() {
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  return {
    getExtendedStoreConfig,
    isFetchingExtendedStoreConfig,
  };
}
